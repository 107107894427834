import revive_payload_client_mXxyhBMr45 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ujNXKJJBOF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sVNcCSBL4f from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/app/plugins/router.js";
import _0_siteConfig_5zLzksxXj0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.7_@types+node@22.10.10_jiti@2.4._szwxnk6dmvwfnpya47xkmeg6sa/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import cloudflare_fVAZwMnNEK from "/opt/buildhome/repo/app/plugins/cloudflare.ts";
import payload_client_UASG8ysnDa from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_MXr3J3FRJN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CzFPavnVt1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_ns3Sn8uR2J from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_AtPfe7Bsqb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.10_better-sqlite3@1_lul3z4334u3biytbeicejgdin4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import plugin_client_mORmjjYNgj from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_client_biaJZEQhc6 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_RAHspWIKy3 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import auth_SOTxJM0yk4 from "/opt/buildhome/repo/app/plugins/auth.ts";
import cal_Y5OBy8NTJc from "/opt/buildhome/repo/app/plugins/cal.ts";
import idle_client_tXXrbdS7zb from "/opt/buildhome/repo/app/plugins/idle.client.ts";
import plugin_auto_pageviews_client_jaHIsWWJcT from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_mXxyhBMr45,
  unhead_ujNXKJJBOF,
  router_sVNcCSBL4f,
  _0_siteConfig_5zLzksxXj0,
  cloudflare_fVAZwMnNEK,
  payload_client_UASG8ysnDa,
  navigation_repaint_client_MXr3J3FRJN,
  check_outdated_build_client_CzFPavnVt1,
  view_transitions_client_ns3Sn8uR2J,
  chunk_reload_client_AtPfe7Bsqb,
  components_plugin_zlvi6dcIsi,
  plugin_client_mORmjjYNgj,
  plugin_client_biaJZEQhc6,
  unocss_RAHspWIKy3,
  auth_SOTxJM0yk4,
  cal_Y5OBy8NTJc,
  idle_client_tXXrbdS7zb,
  plugin_auto_pageviews_client_jaHIsWWJcT
]